import * as React from 'react'
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews'
import { PrismicRichText, PrismicLink } from '@prismicio/react'
import  Container from "../components/Container"

const ThankYou = (lang) => (
  <Container>
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh', gap: 60}}>
      <h1>Merci de nous contacter!</h1>
      <p>Nous vous répondrons dans les 72 heures.</p>
      <PrismicLink href="/fr/contact" className="BtnPrimary">
        Revenir au contact
      </PrismicLink>
    </div>
  </Container>
)

export default withPrismicUnpublishedPreview(ThankYou)
